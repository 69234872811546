<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>
                الفواتير والاشتراكات
            </h5>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            رقم الطلب
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            الاجمالي
                        </th>
                        <th>
                            تاريخ إنتهاء الإشتراك
                        </th>
                        <th>
                            طباعة الفاتورة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order._id">
                            <td>
                                {{ order.order_id }}
                            </td>
                            <td>
                                {{ order.date.split(" ")[0] }}
                            </td>
                            <td>
                                {{ order.service }}
                            </td>
                            <td>
                                <span v-if="order.status == 1" class="btn btn-sm btn-success" style="border-radius: 0px;">
                                    <i class="fa fa-check"></i>
                                    تم بنجاح
                                </span>
                                <span v-if="order.status == 2" class="btn btn-danger btn-sm" style="border-radius: 0px;">
                                    <i class="fa fa-times"></i>
                                    فشل
                                </span>
                                <span v-if="order.status == 0" class="btn btn-warning btn-sm" style="border-radius: 0px;">
                                    <i class="fa fa-clock-o"></i>
                                    في الانتظار
                                </span>
                            </td>
                            <td>
                                {{ order.total }} ريال
                            </td>
                            <td>
                                {{ order.exdate }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-success" style="border-radius: 0px;" @click="print(order)" v-if="order.status == 1">
                                    <i class="fa fa-print"></i>
                                    طباعة
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            orders: [],
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    created(){
        if(!checkPer("subscribe")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/subscribe/orders', {
            jwt: this.user.jwt,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.orders = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
    methods: {
        print(order){
            var g = this;
            var xxs = "";
            if(order.service_plan.per == 'student'){
                xxs = `
                    <tr>
                        <th>عدد الطلاب</th>
                        <th>${order.order_info.students}</th>
                    </tr>
                `
            }
            if(order.service_plan.per == 'teacher'){
                xxs = `
                    <tr>
                        <th>عدد المعلمين</th>
                        <th>${order.order_info.teachers}</th>
                    </tr>
                `
            }
            var win = window.open("", "tahdir.net", "width=400,height=600");
            win.document.write(`
            <style>
                table{
                width: 100%;
                }
                table, td, th {
                border: 1px solid;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
            </style>
            <div class="card-body text-center" dir='rtl'>
            <p>
                المملكة العربية السعودية
                    <br>
                    ${order.sada ? `مؤسسة الصدى لتقنية المعلومات
                    <br>
                    سجل تجاري: 1128183340
                    <br>
                    جوال: 0580200670
                    <br>
                    الرقم الضريبي: 310458863500003` : `نظام التحضير الذكي`}
            </p>
            <table class="table table-hover table-bordered">
                <tbody><tr>
                    <th>رقم العملية / رقم الطلب</th>
                    <th>${order.order_id}</th>
                </tr>
                <tr>
                    <th>الجوال</th>
                    <th>${g.user.phone}</th>
                </tr>
                <tr>
                    <th>الخدمة</th>
                    <th>${order.service}</th>
                </tr>
                <tr>
                    <th>اجمالي المبلغ الخاضع للضريبة</th>
                    <th>${order.original} ريال</th>
                </tr>
                <tr>
                    <th>ضريبة القيمة المضافة 15%</th>
                    <th>${order.tax} ريال</th>
                </tr>
                <tr>
                    <th>اجمالي المبلغ مع الضريبة 15%</th>
                    <th>${order.total} ريال</th>
                </tr>
                <tr>
                    <th>تاريخ الإشتراك</th>
                    <th>${order.date}</th>
                </tr>
                ${xxs}
                <tr>
                    <th>تاريخ انتهاء الاشتراك</th>
                    <th>${order.exdate}</th>
                </tr>
                <tr>
                    <th>اسم العميل</th>
                    <th>${g.user.name}</th>
                </tr>
                </tbody></table>
                    <center class="col-12 text-center">
                    <br>
                        <img src="https://quickchart.io/chart?chs=150x150&amp;cht=qr&amp;chl=http://s.tahdir.net/subscribes&amp;chld=L|1&amp;choe=UTF-8" alt="">
                    <br>
                    <button style='background: green; color: white; font-size: 16px; padding: 10px' onclick="this.style.display = 'none'; window.print()">طباعة الفاتورة الآن</button>
                    </center>
                </div>
            `);

        }
    }
}
</script>

<style>

</style>